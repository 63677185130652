<template>
  <div class="app-item app-content">
    <h4>修改个人密码</h4>
   <el-form ref="form" :model="form" label-width="80px">
    <el-form-item label="原始密码">
      <el-input
        placeholder="请输入原始密码"
        v-model="form.originalpwd"
        clearable>
      </el-input>
    </el-form-item>
    <el-form-item label="新密码">
      <el-input
        placeholder="请输入新密码"
        v-model="form.newpwd"
        clearable>
      </el-input>
    </el-form-item>
    <el-form-item label="确认密码">
      <el-input
        placeholder="请输入确认密码"
        v-model="form.confirmpwd"
        clearable>
      </el-input>
    </el-form-item>
     <el-form-item >
    <el-button type="danger" @click="submitForm()">提交</el-button>
     </el-form-item>
   </el-form>
  </div>
</template>
<script>
export default {
  data () {
    return {
      form: {
        originalpwd: '',
        newpwd: '',
        confirmpwd: ''
      }
    }
  },
  methods: {
    submitForm () {
      let obj = {
        old_password: this.form.originalpwd,
        new_password: this.form.newpwd,
        new_password_confirmation: this.form.confirmpwd
      }
      this.$store.dispatch('updatePwd', obj).then(res => {
        // console.log(res)
        if (res.code === 0) {
          this.$message({
            message: '密码修改成功,请重新登录',
            type: 'success'
          })
          this.$store.commit('logout')
          this.$router.push({ name: 'Login' })
        } else {
          this.$message({
            message: res.message,
            type: 'error'
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.app-item{
  .el-form-item{
    width:60%
  }
  .el-button{
    padding:12px 60px
  }
}
@media screen and (max-width: 800px) {
  .app-item {
    .el-form-item{
      width:100%
     }
  }
}
</style>
